<template>
    <div class="row">
        <div class="col-md-4 mb-2 position-relative" v-for="(module, index) in modules"
             :key="`card-module-${module.id}${index}`">
            <div class="card">
                <div class="text-center ">
                    <b-skeleton-img
                        v-if="loaded.indexOf(module.id) < 0"
                        style="aspect-ratio: 320 / 480;"
                    />
                    <img v-show="loaded.indexOf(module.id) > -1" :src="module.imgUrl" alt="generica_modulo"
                         class="img-fluid img-module"
                         style="border-bottom-right-radius: 0; border-bottom-left-radius: 0"
                         @load="loaded.push(module.id)">
                    <span v-if="module.show_title" :style="
                            `
                             position: absolute;
                             bottom: 20px;
                             z-index: 1000;
                             font-size: 16px;
                             font-weight: bold;
                             line-height: 1;
                             bottom: 90px;
                             left: 15px;
                             right: 15px;
                             color: ${module.mentoring_color ? color : '#FFF'}
                            `">
                        {{ module.title }}
                    </span>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <h6 class="mb-0">Progresso</h6>
                            <h6 class="mb-0">{{ String(module.progress).replace('.', ',') }}%</h6>
                        </div>
                        <div class="progress mb-2" style="height: 10px">
                            <div class="progress-bar" role="progressbar" :style="`width: ${module.progress}%;`"
                                 :aria-valuenow="parseInt(module.progress)" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                        <!--                        <div class="d-flex justify-content-center top-middle">-->
                        <!--                            <div class="d-flex flex-column justify-content-center">-->
                        <!--                                <span class="my-auto" v-if="module.pendencies === 0">Nenhum retorno pendente</span>-->
                        <!--                                <span class="my-auto" v-else-if="module.pendencies === 1">{{ module.pendencies }} retorno pendente</span>-->
                        <!--                                <span class="my-auto" v-else>{{ module.pendencies }} retornos pendentes</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->

                        <div class="row pointer" v-b-toggle="`collapse-${module.id}`">
                            <div class="col-12 mt-2 d-flex justify-content-between">
                                <div class="d-flex">
                                    <h5 class="my-auto">Conteúdos</h5>
                                    <span
                                        class="ml-2 rounded-circle d-flex flex-column justify-content-center text-white"
                                        :class="module.pendencies > 0 ? 'bg-danger' : 'bg-success'"
                                        style="height: 30px;width: 30px;"
                                    >
                                        <b>{{ module.pendencies }}</b>
                                    </span>
                                </div>
                                <i class="my-auto fs-18"
                                   :class="isOpen.indexOf(module.id) > -1 ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
                            </div>
                        </div>

                        <!-- Element to collapse -->
                        <b-collapse :id="`collapse-${module.id}`" @shown="isOpen.push(module.id)"
                                    @hidden="closeCollapse(module.id)">
                            <div class="card-body px-0 pb-0">
                                <ul class="list-group">
                                    <li class="list-group-item d-flex justify-content-between text-left px-2 text-success pointer"
                                        :key="`list-tasks-${task.id}`"
                                        :class="{
                                        'text-success': task.status === 3,
                                        'text-warning': task.status === 2,
                                        'text-muted': task.status === 1,
                                        }"
                                        @click="viewResponse(task)"
                                        v-for="task in module.tasks">
                                        <span>
                                            {{ task.contents.subject }}
                                        </span>

                                        <i class="fa ml-2 my-auto"
                                           :class="{
                                        'fa-check-circle': task.status === 3,
                                        'fa-exclamation-circle': task.status === 2,
                                        'fa-clock': task.status === 1,
                                        }"
                                        ></i>
                                    </li>
                                </ul>
                            </div>
                        </b-collapse>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    props: {
        modules: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            show_details: false,
            color: null,
            mouseOn: false,
            loaded: [],
            isOpen: [],
            mentoring: this.$route.params.mentoring,
            mentee: this.$route.params.mentee
        }
    },

    methods: {
        viewResponse(task) {
            let url = null;
            if (task.contents.type === 3) {
                url = `/mentorias/detalhes/${this.mentoring}/mentorando/${this.mentee}/formulario/${task.contents.id}/${task.contents.form_id}`;
            } else if (task.contents.type === 4) {
                url = `/mentorias/detalhes/${this.mentoring}/mentorando/${this.mentee}/atividade/${task.contents.id}`;
            } else if(task.contents.type === 5) {
                url = '/agenda';
            }

            if (url) this.$router.push(url)
        },
        closeCollapse(id) {
            let index = -1;
            this.isOpen.forEach((el, i) => {
                if (el === id) index = i;
            })

            if (index > -1) this.isOpen.splice(index, 1);
        },
        editModule(indexModule) {
            this.$emit('edit-module', indexModule);
        }
    },

    created() {
        var style = document.createElement('style');
        style.type = 'text/css';

        var css = `
                .slick-prev:before, .slick-next:before {
                color: rgb(128, 128, 128) !important;
                }

                .col.position-relative.pointer {
                      transition: transform 0.6s ease-in-out;
                }

                .col.position-relative.pointer:hover {
                      transform: scale(1.1);
                }

                .top-middle {
                    top: calc(50% - 15px)
                }
        `;

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        document.head.appendChild(style);
        this.color = document.getElementById('mentoring_color');
    },
}
</script>
