var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},_vm._l((_vm.modules),function(module,index){return _c('div',{key:`card-module-${module.id}${index}`,staticClass:"col-md-4 mb-2 position-relative"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"text-center"},[(_vm.loaded.indexOf(module.id) < 0)?_c('b-skeleton-img',{staticStyle:{"aspect-ratio":"320 / 480"}}):_vm._e(),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded.indexOf(module.id) > -1),expression:"loaded.indexOf(module.id) > -1"}],staticClass:"img-fluid img-module",staticStyle:{"border-bottom-right-radius":"0","border-bottom-left-radius":"0"},attrs:{"src":module.imgUrl,"alt":"generica_modulo"},on:{"load":function($event){return _vm.loaded.push(module.id)}}}),(module.show_title)?_c('span',{style:(`
                         position: absolute;
                         bottom: 20px;
                         z-index: 1000;
                         font-size: 16px;
                         font-weight: bold;
                         line-height: 1;
                         bottom: 90px;
                         left: 15px;
                         right: 15px;
                         color: ${module.mentoring_color ? _vm.color : '#FFF'}
                        `)},[_vm._v(" "+_vm._s(module.title)+" ")]):_vm._e(),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h6',{staticClass:"mb-0"},[_vm._v("Progresso")]),_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(String(module.progress).replace('.', ','))+"%")])]),_c('div',{staticClass:"progress mb-2",staticStyle:{"height":"10px"}},[_c('div',{staticClass:"progress-bar",style:(`width: ${module.progress}%;`),attrs:{"role":"progressbar","aria-valuenow":parseInt(module.progress),"aria-valuemin":"0","aria-valuemax":"100"}})]),_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-${module.id}`),expression:"`collapse-${module.id}`"}],staticClass:"row pointer"},[_c('div',{staticClass:"col-12 mt-2 d-flex justify-content-between"},[_c('div',{staticClass:"d-flex"},[_c('h5',{staticClass:"my-auto"},[_vm._v("Conteúdos")]),_c('span',{staticClass:"ml-2 rounded-circle d-flex flex-column justify-content-center text-white",class:module.pendencies > 0 ? 'bg-danger' : 'bg-success',staticStyle:{"height":"30px","width":"30px"}},[_c('b',[_vm._v(_vm._s(module.pendencies))])])]),_c('i',{staticClass:"my-auto fs-18",class:_vm.isOpen.indexOf(module.id) > -1 ? 'fa fa-caret-up' : 'fa fa-caret-down'})])]),_c('b-collapse',{attrs:{"id":`collapse-${module.id}`},on:{"shown":function($event){return _vm.isOpen.push(module.id)},"hidden":function($event){return _vm.closeCollapse(module.id)}}},[_c('div',{staticClass:"card-body px-0 pb-0"},[_c('ul',{staticClass:"list-group"},_vm._l((module.tasks),function(task){return _c('li',{key:`list-tasks-${task.id}`,staticClass:"list-group-item d-flex justify-content-between text-left px-2 text-success pointer",class:{
                                    'text-success': task.status === 3,
                                    'text-warning': task.status === 2,
                                    'text-muted': task.status === 1,
                                    },on:{"click":function($event){return _vm.viewResponse(task)}}},[_c('span',[_vm._v(" "+_vm._s(task.contents.subject)+" ")]),_c('i',{staticClass:"fa ml-2 my-auto",class:{
                                    'fa-check-circle': task.status === 3,
                                    'fa-exclamation-circle': task.status === 2,
                                    'fa-clock': task.status === 1,
                                    }})])}),0)])])],1)],1)])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }